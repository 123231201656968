import { CheckIcon } from "@heroicons/react/solid";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
/* import SectionMagazine8 from "components/SectionMagazine8"; */
import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

import Checkbox from "shared/Checkbox/Checkbox";

import { pricingsServices } from "data/data";
import { PricingItem } from "data/data";
import SectionServices1 from "components/SectionServices/SectionServices1";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ModalPlan from "components/ModalPlan/ModalPlan";

export interface PageSubcriptionProps {
  className?: string;
}

const valueItems = {
  landing:49990,
  paginas:79990,
  pack:40000,
  hosting:40000,
  ssl:35000,
  ceo:5000,
  email:5000
};

const parseToCLPCurrency = (number:number) => {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  }).format(number);
};

const PageSubcription2: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [total, setTotal] = useState(0);
  const [landingChecked, setLandingChecked] = useState(false);
  const [paginasChecked, setPaginasChecked] = useState(false);
  const [seoChecked, setSeoChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [packChecked, setPackChecked] = useState(false);
  const [planSelect, setPlanSelect] = useState('');
  const [detailCustomPlan, setDetailCustomPlan] = useState('');

  /* Ejemplo para poner descuento en plan Custom */
  /* const [discountCustom, setDiscountCustom] = useState(0.8); el precio estara al 80% */
  /*  const [discountCustomText, setDiscountCustomText] = useState('20%'); saldra la etiqueta de desceunto */
  const [discountCustom, setDiscountCustom] = useState(0);
  const [discountCustomText, setDiscountCustomText] = useState('');

  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal(planName: string) {
    setIsOpen(true)
    setPlanSelect(planName);
  }

  useEffect(() => {
    // Actualizar el detalle personalizado basado en los checkboxes seleccionados
    const selectedCheckboxes = [];
    if (landingChecked) selectedCheckboxes.push('Landing');
    if (paginasChecked) selectedCheckboxes.push('Páginas');
    if (seoChecked) selectedCheckboxes.push('SEO');
    if (packChecked) selectedCheckboxes.push('Conjunto');

    setDetailCustomPlan(selectedCheckboxes.join(', '));
  }, [landingChecked, paginasChecked, seoChecked, packChecked]);

  const handleCheckboxChange = (checkboxName: string, value: boolean) => {
    switch (checkboxName) {
      case 'Landing':

        setLandingChecked(value);
        setPaginasChecked(false);

        if(paginasChecked){

          setTotal((prevTotal) => (value ? prevTotal + valueItems.landing - valueItems.paginas : prevTotal - valueItems.landing));
        }else{
          /* setDetailCustomPlan((prevDetalle) => value ? 
            prevDetalle ? prevDetalle + ', ' + checkboxName : checkboxName 
            : 
            prevDetalle.split(', ').filter((item) => item !== checkboxName).join(', ')
            ); */
          setTotal((prevTotal) => (value ? prevTotal + valueItems.landing : prevTotal - valueItems.landing));
        }
        break;
      case 'paginas':
        setPaginasChecked(value);
        setLandingChecked(false);

        if(landingChecked){

          setTotal((prevTotal) => (value ? prevTotal + valueItems.paginas - valueItems.landing : prevTotal - valueItems.paginas));
        
        }else{
          
          /* setDetailCustomPlan((prevDetalle) => value ? 
            prevDetalle ? prevDetalle + ', ' + checkboxName : checkboxName 
            : 
            prevDetalle.split(', ').filter((item) => item !== checkboxName).join(', ')
            ); */
          setTotal((prevTotal) => (value ? prevTotal + valueItems.paginas : prevTotal - valueItems.paginas));
        }
        
        break;
      case 'pack':

        /* setDetailCustomPlan((prevDetalle) => value ? 
        prevDetalle ? prevDetalle + ', ' + checkboxName : checkboxName 
        : 
        prevDetalle.split(', ').filter((item) => item !== checkboxName).join(', ')
        ); */
        /* setDetailCustomPlan(value ? ['conjunto'] : []) */
        setPackChecked(value)
        setTotal((prevTotal) => (value ? prevTotal + valueItems.pack : prevTotal - valueItems.pack));
        break;

      case 'SEO':
        /* setDetailCustomPlan((prevDetalle) => value ? 
        prevDetalle ? prevDetalle + ', ' + checkboxName : checkboxName 
        : 
        prevDetalle.split(', ').filter((item) => item !== checkboxName).join(', ')
        ); */
        /* setDetailCustomPlan(value ? ['SEO'] : []) */
        setSeoChecked(value)
        setTotal((prevTotal) => (value ? prevTotal + valueItems.ceo : prevTotal - valueItems.ceo));
        break;

      case 'Email':
        /* setDetailCustomPlan((prevDetalle) => value ? 
        prevDetalle ? prevDetalle + ', ' + checkboxName : checkboxName 
        : 
        prevDetalle.split(', ').filter((item) => item !== checkboxName).join(', ')
        ); */
        /* setDetailCustomPlan(value ? ['SEO'] : []) */
        setEmailChecked(value)
        setTotal((prevTotal) => (value ? prevTotal + valueItems.email : prevTotal - valueItems.email));
        break;
      
      default:
        break;
    }

  };


  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          pricing.isPopular
            ? "border-primary-500 dark:border-neutral-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>

          {pricing.offer?.discount ? (
            <h2 className="text-xl leading-none flex items-center">
              <span style={{textDecoration: 'line-through'}}>{pricing.pricing}</span>
              <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs ml-3 mb-2 rounded-full z-10">
                {pricing.offer?.discount} OFF
              </span>
            </h2>): ''}

          <h2 className="text-5xl leading-none flex items-center">
            <span>{pricing.offer?.pricing ? pricing.offer?.pricing : pricing.pricing }</span>
            <span className="text-base ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
          <p className="text-center mt-1">Luego {pricing.pricing2} /mes</p>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000 dark:text-neutral-500">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            <ButtonPrimary type="button" onClick={() => openModal(pricing.name)}>Seleccionar</ButtonPrimary>
          ) : (
            <ButtonSecondary type="button" onClick={() => openModal(pricing.name)}>
              <span className="font-medium">Seleccionar</span>
            </ButtonSecondary>
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="py-6 xl:py-2">
        <div className="">
          <div
            className={`nc-PageSubcription container pb-24 lg:pb-20 ${className}`}
            data-nc-id="PageSubcription"
          >
            <header className="text-center max-w-2xl mx-auto my-20" id="PlanesSection">
              <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span>
                Plan & Suscripción
              </h2>
              <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                Precios que se adaptan a las necesidades de cualquier tamaño de empresa.
              </span>
            </header>
            <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
              <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
              <div
                  className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
                    "border-neutral-100 dark:border-neutral-700"
                  }`}
                >
                  <div className="mb-8">
                    <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                    Custom
                    </h3>
                    {discountCustom > 0 ? (
                      <>
                      <h2 className="text-xl leading-none flex items-center">
                        <span style={{textDecoration: 'line-through'}}>{parseToCLPCurrency(total)}</span>
                        <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs ml-3 mb-2 rounded-full z-10">
                          {discountCustomText} OFF
                        </span>
                      </h2>
                        <h2 className="text-5xl leading-none flex items-center">
                          <span>
                            {paginasChecked || landingChecked
                              ? parseToCLPCurrency(Math.round(total * discountCustom) - 2)
                              : parseToCLPCurrency(Math.round(total * discountCustom))
                            }
                          </span>
                          <span className="text-base ml-1 font-normal text-neutral-500">
                            /primer pago
                          </span>
                        </h2>
                      </>
                      
                    ) : (
                      <h2 className="text-5xl leading-none flex items-center">
                        <span>{parseToCLPCurrency(total)}</span>
                        <span className="text-base ml-1 font-normal text-neutral-500">
                          /primer pago
                        </span>
                      </h2>
                    )}

                    <p className="text-center mt-1">Luego $6.990 /mes</p>
                  </div>
                  <nav className="space-y-4 mb-8">
                      <li className="flex items-center" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <Checkbox
                            name='Landing'
                            label='Landing'
                            checked={landingChecked}
                            onChange={(value) => handleCheckboxChange('Landing', value)}
                          />
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <Checkbox
                            name='paginas'
                            label='Páginas'
                            checked={paginasChecked}
                            onChange={(value) => handleCheckboxChange('paginas', value)}
                          />
                        </span>
                      </li>
                      <li className="flex flex-col" >
                        <span className="ml-2.5 sm:ml-6 mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <ul className="ml-3">
                            <li>- Inicio.</li>
                            <li>- Servicios.</li>
                            <li>- Nosotros.</li>
                            <li>- Contacto.</li>
                            <li>- $5.000 para añadir página extras.</li>
                          </ul>
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <Checkbox
                            name='pack'
                            label='Conjunto de:'
                            checked={packChecked}
                            onChange={(value) => handleCheckboxChange('pack', value)}
                          />
                        </span>
                      </li>
                      <li className="flex flex-col" >
                        <span className="ml-2.5 sm:ml-6 mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <ul className="ml-3">
                            <li>- Dominio</li>
                            <li>- Hosting</li>
                            <li>- Certificado SSL</li>
                            <li>- Diseño Adaptativo</li>
                          </ul>             
                        </span>

                      </li>
                      <li className="flex items-center" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <Checkbox
                            name='SEO'
                            label='Posicionamiento Web (SEO)'
                            checked={seoChecked}
                            onChange={(value) => handleCheckboxChange('SEO', value)}
                          />
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="mr-4 inline-flex flex-shrink-0 text-neutral-700 dark:text-neutral-300">
                          <Checkbox
                            name='Email'
                            label='Email Personalizados'
                            checked={emailChecked}
                            onChange={(value) => handleCheckboxChange('Email', value)}
                          />
                        </span>
                      </li>
                  </nav>
                  <div className="flex flex-col mt-auto">

                      <ButtonSecondary type="button" onClick={() => openModal('Custom')} disabled={total === 0 ? true : false}>
                        <span className="font-medium">Seleccionar</span>
                      </ButtonSecondary>

                    <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
                    Al seleccionar, estás aceptando los términos y condiciones.
                    </p>
                  </div>
                </div>
                {pricingsServices.map(renderPricingItem)}              
              </div>
            </section>
            <span className="block text-sm mt-5 text-neutral-700 sm:text-base dark:text-neutral-400">
              ⓘ Sobre la subscripción, Si no paga dentro de los 3 días hábiles luego de vencido el mes, se dará de baja su página hasta que realize el pago de la suscripción.
            </span>
          </div>
        </div>
      </div>
      
      <ModalPlan isOpen={isOpen} closeModal={closeModal} planSelect={planSelect} detailCustomPlan={detailCustomPlan}></ModalPlan>
    </>
  );
};

export default PageSubcription2;
