import React from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionMagazine8 from "components/SectionMagazine8";
import PageSubcription2 from "containers/PageSubcription/PageSubcription2";
// import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionSliderCardNftVideo from "components/SectionSliderCardNftVideo";



function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>BecauseYes | Inicio</title>
        <meta name="description" content="BecauseYes, Bienvenidos a nuestro equipo 
        de programadores altamente calificados y dedicados a llevar tus ideas 
        a la vida digital. Somos un conjunto apasionado de profesionales 
        que combinan conocimientos técnicos avanzados con una creatividad sin límites."/>
        
        <meta name="keywords" content="porque si, BecauseYes, Because Yes, becauseyes, 
        because yes, crea tu sitio web, sitio web, customización, 
        servicios, ventajas, planes y suscripciones, mantención web, 
        hosting, certificado ssl, diseño responsive, SEO, posicionamiento SEO,
        cómo funciona, how work, contacta, plan custom, plan standard, plan premium, servicios externos,
        servicio contabilidad, servicio markenting, servicio diseño, descuentos,
        registrate. " />

        <link rel="canonical" href="https://becauseyes.cl/" />
        <meta name="robots" content="follow" />
        <meta name="author" content="BecauseYes"/>
        <meta name="publisher" content="BecauseYes"/>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      {/* <div className="relative p-1 w-[100vw] bg-red-600/90 dark:bg-red-600/80 text-white">
        <p className="hidden sm:block absolute bg-secondary-800 dark:bg-gray-100 h-full top-0 right-[20%] 2xl:right-[27%] w-5 z-40"></p>
        <p className="hidden sm:block absolute bg-white dark:bg-secondary-700 h-full top-0 right-[23%]  2xl:right-[29%] w-5 z-40"></p>
        <div className="flex justify-center">
          <p className="text-xs sm:text-sm text-center dark:text-gray-50">Un año más de independencia. Felices Fiestas Patrias a todos los chilenos. ¡Viva Chile!</p>
        </div>
      </div> */}
      <div className="container relative mt-5 mb-20 sm:mb-24 lg:mt-20 lg:mb-32" id="HeroSection">
        {/* SECTION HERO */}
        <SectionHero2 />
      </div>
      {/* SECTION LAERGE SLIDER */}
      <div className="bg-neutral-100/70 dark:bg-black/20 py-20 lg:py-32" id="ServicesSection">
        <div className="container" >
          {/* SECCION DE SERVICIOS */}
          <SectionMagazine8 />
        </div>
      </div>
        <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-28" id="HowWorkSection">
          {/* SECTION 2 */}
          {/* SECCION DE COMO FUNCIONA */}
          <SectionHowItWork className="mt-24 lg:mt-40 xl:mt-16" />
        </div>
        <div className="bg-neutral-100/10 dark:bg-black/20 py-6 lg:py-16 lg:pb-8" id="HowWorkSection">
          <div className="container" >
          <SectionSliderCardNftVideo/>
          </div>
        </div>
        <div className=" dark:bg-black/20 py-11 lg:py-1 rounded-xl" id="PlanSection">
          {/* SECCIÓN DE PLANES Y SUSCRIBCIONES */}
          <PageSubcription2 />
        </div>
        {/* SECTION SERVICES EXTERNS  */}
        <div className="py-6 lg:py-10 lg:pb-16" id="ServiceExtern">
          <div className="container" >
          <SectionGridAuthorBox/>
          </div>
        </div>
        {/* FIN SECTION SERVICES EXTERNS  */}

        {/* SECCIÓN DE DESCUENTO */}
        {/* <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-28" id="DiscountSection">
          <SectionSubscribe2 />
        </div>       */}
    
    </div>
  );
}

export default PageHome;
