import React, { FC , useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import CardNFT from "components/CardNFT";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import CardCustomerBox from "components/CardCustomerBox/CardCustomerBox";
import { CustomersData } from "data/data";
import CardNFTVideo from "components/CardNFTVideo";

export interface PageSearchProps {
  className?: string;
}

const PageCustomers: FC<PageSearchProps> = ({ className = "" }) => {
  const [sortedCustomer, setSortedCustomer] = useState(CustomersData)
  const sortOrder = (newOrder: 'asc' | 'desc') => 
    { 
      let sorted = [...CustomersData] //copia el arreglo hasta ahora
        const filtered = sorted.sort((a , b) => {
          return newOrder === 'desc' ? b.id - a.id : a.id - b.id;
      });
      return filtered
    }

    const handleSort = (newOrder: 'asc' | 'desc') => {
      const orderedData = sortOrder(newOrder); 
      setSortedCustomer(orderedData); 
    };

    useEffect(() => {
      handleSort('desc');
    }, []);

  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>BecauseYes | Nuestros clientes</title>
      </Helmet>

        <header className="text-center max-w-2xl mx-auto mt-20 mb-6" id="PlanesSection">
          <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            <span className="mr-4 text-3xl md:text-4xl leading-none">💼</span>
            Nuestros Clientes
          </h2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
            Revisa el trabajo realizados por nuestro equipo
          </span>
        </header>

      <div className="container py-16 lg:pb-28 lg:pt-8 space-y-16 lg:space-y-24">
        <main>
          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {sortedCustomer.map((item) => (
              <div key={item.id} className={``}>
                <CardNFTVideo featuredImage={item.image} name={item.name} hrefWeb={item.href}/>
              </div>
            ))}
          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageCustomers;
